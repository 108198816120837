<template>
  <div>
    <page-title :title="`Counter Parties`">
      <template slot="button">
        <a
          href="#"
          @click.prevent="show_create = true"
          class="btn btn-secondary btn-sm float-right"
        >
          <i class="lni-plus mr-2"></i> Add Counter Party
        </a>
      </template>
    </page-title>
    <div class="card">
      <div class="card-body table-responsive">
        <table class="table table-striped table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Category</th>
              <th>Physical Address</th>
              <th>Postal Address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(party, i) in counter_parties"
              :key="`counterparty-${i}`"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ party.name }}</td>
              <td>{{ party.category }}</td>
              <td>{{ party.physical_address }}</td>
              <td>{{ party.postal_address }}</td>
              <td>
                <a
                  class="btn btn-warning btn-sm mr-2"
                  href="#"
                  @click.prevent="edit_counterparty = party; show_edit = true"
                  ><i class="lni-trash mr-2"></i> Edit</a
                >
                <a
                  class="btn btn-danger btn-sm"
                  href="#"
                  @click.prevent="deleteParty(party)"
                  ><i class="lni-trash mr-2"></i> delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal :showModal="show_create" @close="show_create = false;">
      <template slot="header">
        <h3>Add Counterparty</h3>
      </template>
      <create @close="show_create = false" @added="fetch" />
    </modal>
    <modal :showModal="show_edit" @close="show_edit = false;">
      <template slot="header">
        <h3>Edit Counterparty</h3>
      </template>
      <edit @close="show_edit = false" :counterparty="edit_counterparty" @added="fetch" />
    </modal>
  </div>
</template>

<script>
import $ from "jquery";
import Create from './Create'
import Edit from './Edit'

export default {
  components: {
    Create, Edit
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      counter_parties: [],
      show_create: false,
      edit_counterparty: {},
      show_edit: false
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios.get("/api/v1/dashboard/counterparties").then((response) => {
        this.counter_parties = response.data;
        setTimeout(() => {
          $("table").DataTable();
          this.$loader.stop();
        }, 1000);
      });
    },

    deleteParty(party) {
      this.$axios
        .delete("/api/v1/dashboard/counterparties/" + party.id)
        .then(() => {
          this.fetch();
        });
    },
  },
};
</script>
